import { memo, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import styled from '@emotion/styled';
import { fill, fill_horizontal_all_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { fontWeight400, fontWeight600 } from '~/css/font';
import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
const SymbolCardHoldingProfitBody1 = memo(function SymbolCardHoldingProfitBody1(props) {
    const { currentSymbol, symbol, qty, averagePrice, marketPrice, pnl, lastModifiedDatetime } = props;
    useEffect(() => {
        signalrStore2.addQuote(props.symbol);
        return () => {
            signalrStore2.removeQuote(props.symbol);
        };
    }, [props.symbol]);
    const symbolName = fr_instrument.getName(symbol);
    const symbolInfo = useSnapshot(signalrStore2.values.quote)[props.symbol];
    const quoteChanges = processQuoteToReadable(symbolInfo);
    const modifiedDate = lastModifiedDatetime?.substring(5, 10).replaceAll('-', '/');
    const close = symbolInfo?.close ?? 0;
    const staticState = useSnapshot(staticStore);
    const futuresDisctionary = staticState.symbol.stockFuturesMapper;
    const stockBondDisctionary = staticState.symbol.stockBondsMapper;
    const theme = useThemeStore(s => s.theme);
    const limitUp = symbolInfo?.close === symbolInfo?.limitUpPrice;
    const limitDn = symbolInfo?.close === symbolInfo?.limitDownPrice;
    //顏色邏輯判斷
    /** 收盤報價色 */
    const qouteFill = quoteChanges.closeChange >= 0 ? classesFill.upFill : classesFill.dnFill;
    /** 漲停背景色 */
    const limitBackground = limitUp ? classesFill.limitUpFill : limitDn ? classesFill.limitDnFill : '';
    const isAvtive = props.symbol === props.currentSymbol ? '-active' : '';
    /** 報酬率 */
    const profitPercentage = () => {
        if (qty > 0 && close !== 0) {
            return (close / averagePrice - 1) * 100;
        }
        else if (qty < 0 && close !== 0) {
            return (1 - close / averagePrice) * 100;
        }
        else
            return 0;
    };
    const signSymbol = () => {
        if (profitPercentage() > 0) {
            return '+';
        }
        if (profitPercentage() < 0) {
            return '';
        }
    };
    const profitFill = () => {
        if (profitPercentage() > 0) {
            return classesFill.upFill;
        }
        if (profitPercentage() < 0) {
            return classesFill.dnFill;
        }
        if (profitPercentage() === 0) {
            return null;
        }
        //-----------------
    };
    return (<classes.Root css={baseStyled}>
      <classes.container active={currentSymbol === symbol} onClick={event => {
            props.onClick?.();
        }} className={theme + isAvtive}>
        {futuresDisctionary?.[symbol] && <classes.dictionary>期</classes.dictionary>}
        {stockBondDisctionary?.[symbol] && (<classes.dictionary css={css `
              background-color: #378be0 !important;
            `}>
            債
          </classes.dictionary>)}
        <classes.item column='1' row='2 / 3' className={theme} css={classesFont.fontSmall}>
          {symbol}
        </classes.item>
        <classes.item column='1' row='1 / 2' className={theme} css={classesFont.fontSmall}>
          {symbolName}
        </classes.item>
        <classes.item column='2' row='1 / 3' value={quoteChanges.closeChangePercent} className={theme} css={qouteFill}>
          {close}
        </classes.item>
        <classes.item column='3' row='1 / 3' className={theme} css={qouteFill}>
          {averagePrice}
        </classes.item>
        <classes.item column='4' row='1 / 3' value={profitPercentage()} className={theme} css={profitFill()}>
          {signSymbol()}
          {profitPercentage().toFixed(2)}%
        </classes.item>
        <classes.item column='5' row='1 / 3' className={theme}>
          {modifiedDate}
        </classes.item>
      </classes.container>
    </classes.Root>);
});
const SymbolCardHoldingProfitBody2 = memo(function SymbolCardHoldingProfitBody2(props) {
    const { currentSymbol, symbol, qty, averagePrice, marketPrice, pnl, lastModifiedDatetime } = props;
    useEffect(() => {
        signalrStore2.addQuote(props.symbol);
        return () => {
            signalrStore2.removeQuote(props.symbol);
        };
    }, [props.symbol]);
    const symbolName = fr_instrument.getName(symbol);
    const symbolInfo = useSnapshot(signalrStore2.values.quote)[props.symbol];
    const quoteChanges = processQuoteToReadable(symbolInfo);
    const modifiedDate = lastModifiedDatetime?.substring(5, 10).replaceAll('-', '/');
    const close = symbolInfo?.close ?? 0;
    const staticState = useSnapshot(staticStore);
    const futuresDisctionary = staticState.symbol.stockFuturesMapper;
    const stockBondDisctionary = staticState.symbol.stockBondsMapper;
    const theme = useThemeStore(s => s.theme);
    const limitUp = symbolInfo?.close === symbolInfo?.limitUpPrice;
    const limitDn = symbolInfo?.close === symbolInfo?.limitDownPrice;
    //顏色邏輯判斷
    /** 收盤報價色 */
    const qouteFill = quoteChanges.closeChange >= 0 ? classesFill.upFill : classesFill.dnFill;
    /** 漲停背景色 */
    const limitBackground = limitUp ? classesFill.limitUpFill : limitDn ? classesFill.limitDnFill : '';
    const isAvtive = props.symbol === props.currentSymbol ? '-active' : '';
    /** 報酬率 */
    const profitPercentage = () => {
        if (qty > 0 && close !== 0) {
            return (close / averagePrice - 1) * 100;
        }
        else if (qty < 0 && close !== 0) {
            return (1 - close / averagePrice) * 100;
        }
        else
            return 0;
    };
    const signSymbol = (value) => {
        if (value > 0) {
            return '+';
        }
        if (value < 0) {
            return '';
        }
    };
    const profitFill = () => {
        if (profitPercentage() > 0) {
            return classesFill.upFill;
        }
        if (profitPercentage() < 0) {
            return classesFill.dnFill;
        }
        if (profitPercentage() === 0) {
            return null;
        }
        //-----------------
    };
    return (<classes.Root css={baseStyled}>
      <classes.container active={currentSymbol === symbol} onClick={event => {
            props.onClick?.();
        }} className={theme + isAvtive}>
        {futuresDisctionary?.[symbol] && <classes.dictionary>期</classes.dictionary>}
        {stockBondDisctionary?.[symbol] && (<classes.dictionary css={css `
              background-color: #378be0 !important;
            `}>
            債
          </classes.dictionary>)}

        <classes.item column='1' row='2 / 3' className={theme} css={classesFont.fontSmall}>
          {symbol}
        </classes.item>
        <classes.item column='1' row='1 / 2' className={theme} css={classesFont.fontSmall}>
          {symbolName}
        </classes.item>
        <classes.item column='2' row='1 / 3' value={quoteChanges.closeChangePercent} className={theme} css={qouteFill}>
          {close}
        </classes.item>
        <classes.item column='3' row='1 / 3' className={theme} css={qouteFill}>
          {signSymbol(quoteChanges.closeChange)}
          {quoteChanges.closeChangePercent.toFixed(2)}%
        </classes.item>
        <classes.item column='4' row='1 / 3' value={profitPercentage()} className={theme} css={profitFill()}>
          {signSymbol(profitPercentage())}
          {profitPercentage().toFixed(2)}%
        </classes.item>
        <classes.item column='5' row='1 / 3' className={theme}>
          {modifiedDate}
        </classes.item>
      </classes.container>
    </classes.Root>);
});
const classesFont = {
    fontSmall: css `
    font-size: 12px;
  `,
    fontDefault: css `
    font-size: 14px;
  `,
};
const classesFill = {
    upFill: css `
    &.dark {
      color: #fb0000;
    }
    &.light {
      color: #aa0000;
    }
  `,
    dnFill: css `
    &.dark {
      color: #00ff00;
    }
    &.light {
      color: #009900;
    }
  `,
    //-----------------
    //商品名稱漲停/跌停時的樣式
    limitUpFill: css `
    &.dark {
      background-color: #fb0000;
    }
    &.light {
      background-color: #aa0000;
    }
    > div {
      color: #ffffff;
    }
  `,
    limitDnFill: css `
    &.dark {
      background-color: #00ff00;
    }
    &.light {
      background-color: #009900;
    }
    > div {
      color: #ffffff;
    }
  `,
    //-----------------
};
const classes = {
    Root: styled.div `
    ${fill};
    height: 40px;
  `,
    container: styled.div `
    ${fill_horizontal_all_center};
    height: 40px;
    display: grid;
    grid-template-columns: 24% 19% 19% 22% 16%;
    grid-template-rows: 50% 50%;
    cursor: pointer;
    transition: 0.3s;
  `,
    item: styled.div `
    ${fill_horizontal_all_center};
    grid-column: ${option => option.column};
    grid-row: ${option => option.row};
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
    dictionary: styled.div `
    ${fill_vertical_all_center};
    ${fontWeight400};
    grid-column: 1;
    grid-row: 1 / 3;
  `,
};
const baseStyled = css `
  ${classes.Root} {
  }
  ${classes.container} {
    font-size: 14px;
    ${fontWeight600};
    padding: 0 4px;
    border-radius: 4px;
    box-shadow:
      0px 2px 1px -1px #00000033,
      0px 1px 1px 0px #00000033,
      0px 1px 3px 0px #00000033;

    &.dark {
      background-color: #131416;
      border: 1px solid #3c3c3c;
      color: #ffffff;
      &:hover {
        background-color: #202020;
      }
    }
    &.light {
      background-color: #fefefe;
      border: 1px solid #ebebeb;
      color: #252525;
      &:hover {
        background-color: #fafafa;
      }
    }
    &.dark-active {
      background-color: #232020;
      border: 1px solid #eeeeee;
      color: #ffffff;
      &:hover {
        background-color: #1f1f1f;
      }
    }
    &.light-active {
      background-color: #fafafa;
      border: 1px solid #888888;
      color: #252525;
      &:hover {
        background-color: #fafafa;
      }
    }
  }
  ${classes.dictionary} {
    width: 16px;
    height: 16px;
    font-size: 12px;
    background-color: #23aa87;
    border-radius: 2px;
    color: #ffffff;
    padding: 4px;
    transform: scale(0.84);
  }
  ${classes.item} {
    ${fill_horizontal_all_center};
  }
`;
export default {
    /** 商品 成交價 成本 報酬率 日期 */
    DisplayStyled1: SymbolCardHoldingProfitBody1,
    /** 商品 成交價 漲跌幅 報酬率 日期 */
    DisplayStyled2: SymbolCardHoldingProfitBody2,
    classes,
    classesFill,
};
